@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b9b9b9;
}

.dayMarker:before {
  content: '';
  width: 4px;
  height: 4px;
  background-color: #ffbeaa;
  border-radius: 100%;
  bottom: 1px;
  left: 0px;
  right: 0px;
  position: absolute;
  margin: auto;
}

.copyDayMarker:before {
  content: '';
  width: 3px;
  height: 3px;
  background-color: #3a3a3a;
  border-radius: 100%;
  bottom: 1px;
  left: 0px;
  right: 0px;
  position: absolute;
  margin: auto;
}

.ms-CalendarDay-daySelected {
  background: #65e10048;
}

.ms-CalendarDay-hoverStyle {
  background-color: #ffbeaa59 !important;
}